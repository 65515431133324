.navbar {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.navbarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}

.icon {
  width: 40px;
  height: 40px;
}

.menuButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.navMenu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transition: max-height 0.3s ease-out;
  max-height: 0;
  overflow: hidden;
  z-index: 1001;
  opacity: 1;
}

.navMenu.open {
  display: block;
  max-height: 300px;
  opacity: 1;
}

.navItem {
  display: block;
  padding: 12px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  z-index: 1002;
  text-decoration: none;
  color: black;
}

.navItem:hover {
  background-color: #f1f1f1;
}

.profileMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  z-index: 1002;
}

.profileMenu div {
  padding: 5px 10px;
  cursor: pointer;
}

.profileMenu div:hover {
  background-color: #e0e0e0;
}

.signOutButton {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}
