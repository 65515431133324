/* DesignChoices.css */

.gridDropdown .dropdown {
  width: 390px;
  /* 6 images * 60px + gaps */
}



.gridItem {
  width: 57px;
  /* 95% of 60px */
  height: 57px;
  /* 95% of 60px */
}


.gridDropdown .dropdown.icon {
  display: none;
}

/* ... [other styles remain unchanged] */

/* Container for the button and grid menu */
.gridContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridMenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* grid-template-columns: repeat(6, 57px); */
  /* 95% of 60px */
  gap: 1px;
  /* 95% of 5px */
  padding: 1px;
  /* 95% of 5px */
  margin-top: 5px;
}


.thickBlackBorder {
  border: 5px solid black;
  margin-top: 5px;
}


.gridItemPressed {
  transform: scale(0.90);

  transition: transform 0.1s;
}