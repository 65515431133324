.center {
  text-align: center;
}

@media (max-width: 600px) {
  h1 {
    font-size: 3em;
  }

  h5 {
    font-size: 1.2em;
    padding: 0 20px;
  }
}