/* TextContentEditModal.css */

.ui.modal {
    font-size: 1.3rem;
    width: 80% !important;
    max-width: 500px;
    max-height: 500px;
    position: fixed;
    /* Use fixed positioning to place the modal over content */
    top: 50%;
    /* Position at half the height of the viewport */
    left: 50%;
    /* Position at half the width of the viewport */
    transform: translate(-50%, -50%);
    /* Offset the modal to center it exactly */
    display: flex;
    /* Enables flexbox for the content */
    align-items: center;
    /* Vertically centers the flex items */
    justify-content: center;
    /* Horizontally centers the flex items */
}

/* Base styles for the modal to ensure it's mobile friendly and responsive */
.ui.modal {
    font-size: 1.2rem;
    /* Larger text size for regular screens */
    width: 80% !important;
    /* Responsive width for better mobile experience */
    max-width: 500px;

    /* Maximum width to prevent overly wide modals */
}

/* Adjusting for smaller screens */
@media only screen and (max-width: 600px) {
    .ui.modal {
        font-size: 2rem;
        /* Smaller text size for mobile devices */
    }

    .ui.modal>.header {
        font-size: 1rem;
        /* Adjust header text size for mobile */
    }

    .ui.form .field>label {
        font-size: 1rem;
        /* Adjust form label size for mobile */
    }

    .ui.form .field>textarea {
        font-size: 1rem;
        /* Adjust textarea size for mobile */
    }

    .ui.modal .actions {
        padding: 1rem;
        /* Adjust padding for action buttons on mobile */
    }

    .ui.button {
        width: 90%;
        /* Full-width buttons for easier touch */
        margin-bottom: 0.5rem;
        /* Space out buttons */
    }
}



@media only screen and (min-width: 601px) {
    .ui.modal {
        font-size: 1.3rem;
        /* Ensure text size is 1.3rem for large screens */
        width: 50%;
        /* Adjust modal width for larger screens, if desired */
    }
}