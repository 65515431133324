/* General Styles */
.story-controls {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f0f0f0;
  /* Light grey background */
}

.story-content,
.spaced-buttons {
  margin-top: 20px;
  margin-bottom: 10px;
  /* Combined margin styles for simplicity */
}

h3,
h4,
.blue-pointer {
  color: blue;
  /* Unified color for these elements */
}

h3 {
  font-size: 1.25em;
}

h4,
.blue-pointer {
  font-size: 1em;
  /* Standardized font size for h4 and .blue-pointer */
}

.blue-pointer {
  cursor: pointer;
  /* Changes the cursor to the pointer/hand icon */
  margin: 2em;
  /* Adds margin for spacing */
}

/* Quiz Items */
.quiz-item,
.quiz-container {
  text-align: center;
  /* Center the container, not the button text */
  margin-bottom: 10px;
  /* Add spacing between buttons */
}

/* Ensure button text is left-aligned */
.quiz-item button {
  text-align: left;
  /* Left-align text inside buttons */
}

/* Story Text Wrapper */
.story-text-wrapper {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 0 10px;
  /* color: red; */
  /* Assuming you want the text color to be red */
  font-size: 1em;
  font-weight: normal;
  text-align: left;
  /* Removed the space between the value and the unit */
  /* Add any other styles you need */
}


/* Media Queries */
@media only screen and (max-width: 600px) {
  .story-controls {
    flex-direction: column;
    align-items: center;
  }

  .story-controls button,
  .quiz-item {
    margin-bottom: 10px;
    /* Adjust space between stacked buttons */
    width: 100%;
    /* Ensure full width for responsiveness */
  }

  /* Keep button text left-aligned on mobile */
  .quiz-item button {
    text-align: left;
    /* Reiterate left alignment for clarity */
  }
}

/* Specific rule for left-aligned text in buttons */
.left-aligned-text {
  text-align: left !important;
  /* Force left alignment */
  display: block !important;
  /* Ensure the button respects text alignment */
  width: 100% !important;
  /* Full width for proper alignment */
}

/* Specific rule for left-aligned text in buttons */
.center-aligned-text {
  text-align: center !important;
  /* Force left alignment */
  display: block !important;
  /* Ensure the button respects text alignment */
  width: 75% !important;
  /* Full width for proper alignment */
}