.inner-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.language-selection {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.credits {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.dropdown-menu {
  position: absolute;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
  margin-top: 8px;
  z-index: 1;
  left: -20px;
  /* Adjust this value as necessary */
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: auto;
  min-width: 150px;
}

.dropdown-menu div {
  margin-bottom: 10px;
  /* Adjust this value to your liking */
}

.center {
  position: relative;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.button-group-container {
  display: flex;
  flex-wrap: wrap; /* Ensure buttons can wrap */
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adds space between buttons */
  margin-top: 5px; /* Space above the container */
  width: 100%; /* Container takes full width */
}

.button-group-container button img {
  max-width: 100%;
  height: auto;
}

.nav-button {
  flex: 1 1 100; /* Each button can flex but base width is 45% */
  max-width: 100%; /* Ensure button does not exceed this width */
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin: 5px; /* Consistent margin around buttons */
  border-radius: 5px;
  border: 2px solid darkgray;
}

@media (min-width: 601px) {
  .dropdown-menu {
    width: auto; /* Reset width for non-mobile views */
    left: 0; /* Reset left for non-mobile views */
    transform: none; /* Reset transform for non-mobile views */
  }
}

@media (max-width: 600px) {
  .inner-container {
    justify-content: space-between;
  }

  .language-selection,
  .credits {
    font-size: 1em;
    font-weight: 600;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: right;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 2px solid black;
    width: 100%;
  }

  .credits {
    margin-left: 0;
  }

  .language-selection:hover,
  .credits:hover {
    background-color: #e0e0e0;
  }

  .dropdown-menu {
    position: absolute;
    background: rgb(254, 254, 254);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 20px;
    margin-top: -10px;
    z-index: 1;
    left: 0;
    display: flex;
    flex-direction: column;
    top: 100%;
    min-width: 90px;
  }

  .dropdown-menu div {
    margin-bottom: 10px;
    /* Adjust this value to your liking */
  }

  .button-group-container button img {
    max-width: 100%;
    height: auto;
  }

  .nav-button {
    flex: 1 1 95%; /* Adapt for small screens */
    max-width: 95%; /* Control max width on small screens */
  }
}
