.contact-form input,
.contact-form textarea,
.contact-form label {
  color: #333 !important;
  /* Dark grey text color */
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}