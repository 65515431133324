.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}



.center-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

h2 {
  color: cornflowerblue;
  font-size: 3em;
  margin: 2px;
}

h3 {
  color: black;
  font-size: 1.5em;
  margin: 2px;
}

h4 {
  color: cornflowerblue;
  font-size: 1.2em;
  margin: 2px;
}

h5 {
  color: cornflowerblue;
  font-size: 1.5em;
  margin: 2px;
}

h6 {
  color: blue;
  font-size: 2em;
  margin: 2px;
}

#temp {
  width: 3em;
}



nav {
  padding: 16px 32px;
}


nav>div {
  display: flex;
  gap: 5px;
  align-items: center;
  /* Add this line */
}


@media (min-width: 601px) {
  nav {
    background: aliceblue;
  }
}


nav a {
  margin-right: 16px;
}

nav a.active {
  text-decoration: none;
  font-weight: bold;
}

label {
  font-size: 1.5em;
  font-family: 'Courier New', Courier, monospace;
}

/* Login Page */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.login-page h1 {
  color: blue;
  font-size: 6em;
  margin-bottom: 1rem;
}

.login-page h5 {
  color: black;
  font-size: 1.5em;
  margin-bottom: 2rem;
}

.login-form-container {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.login-form label {
  font-size: 1.5em;
  font-family: 'Courier New', Courier, monospace;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  font-size: 1.5rem !important;
}

.login-form input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.login-btn {
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.login-btn:hover {
  background-color: darkblue;
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.5rem;
  /* Increase the font size */
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}



.signup-btn {
  background-color: black;
  color: white;
  padding: 6px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.signup-btn:hover {
  background-color: darkgray;
}

.password-reset {
  font-size: 0.75em;
  color: black;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1rem;
}

.password-reset:hover {
  color: darkgray;
}

.create-account-btn {
  background-color: orange;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.create-account-btn:hover {
  background-color: darkorange;
}

.studentAge {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgb(247, 247, 150);
  color: black;
  border: 1px solid rgb(128, 121, 121);
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  background-color: yellow;
}

.studentAge:not(.selected) {
  background-color: rgb(244, 244, 108);
}

.selected {
  background-color: rgb(0, 255, 4);
}

.error-message {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
}

.section {
  border: 2px solid #ddd;
  margin: 20px;
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.ui.form .field>label {
  color: cornflowerblue;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 20px 0 3px 0;
}


.question:hover {
  font-weight: bold;
}

/* Modal CSS */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* Add hover style */
li:hover {
  font-weight: bold;
  color: green;
  cursor: pointer;
}

/* Add non-hover style */
li {
  font-weight: bold;
  color: blue;
  cursor: pointer;
}

.item-hovered {
  font-weight: bold;
  color: green;
  cursor: pointer;
}

/* Add hover style */
.summary-text:hover {
  color: green;
  cursor: pointer;
}

/* Add non-hover style */
.summary-text {
  color: blue;
  cursor: pointer;
}


/* Add hover style */
.vocabulary-text:hover {
  color: green;
  cursor: pointer;
}

/* Add non-hover style */
.vocabulary-text {
  color: blue;
  cursor: pointer;
}



.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.custom-modal .content {
  max-height: 400px;
  overflow-y: auto;
}

.custom-modal .modal-textarea {
  width: 70%;
  height: 300px;
  /* adjust this value as necessary */
  resize: none;
}